import * as React from 'react';
import Heading from '../components/heading';
import Text from '../components/text';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import ChestBump from '../assets/chestbump.mp4';
import Layout from '../components/layout';
import AnimateIn from '../components/animate-in';
import map from 'lodash/map';
import SEO from '../components/seo';
import { Controller, Scene } from 'react-scrollmagic';
import { Tween } from 'react-gsap';
import './index.scss';

const ZoomingImageWrapper = ({ fluid, alt, className, progress }) => {
    return (
        <div style={{ overflow: 'hidden' }}>
            <Tween
                from={{
                    transform: 'scale(1)',
                }}
                to={{
                    transform: 'scale(1.15)',
                }}
                totalProgress={progress}
                paused
            >
                <div>
                    <Img fluid={fluid} alt={alt} className={className} />
                </div>
            </Tween>
        </div>
    );
};

const StorySection = ({ storyContent, imageLeft, index }) => {
    const className = `story--section ${
        imageLeft ? 'story--section_image-left' : ''
    }`;

    return (
        <AnimateIn onScroll>
            <div className={className}>
                <Text size="large" className="story--text">
                    {storyContent.text}
                </Text>
                <Img
                    className="story--image"
                    fluid={storyContent.image.src}
                    alt={storyContent.image.alt}
                    style={index === 2 ? { height: '560px' } : {}}
                />
            </div>
        </AnimateIn>
    );
};

// markup
const IndexPage = ({ data }) => {
    const content = {
        title: 'Maddy & Andrew',
        subtitle: 'October 23, 2021 – Dallas, TX',
        event: {
            date: 'Saturday, October 23rd, 2021',
            time: 'five in the evening',
            venueName: 'The Cliff House',
            venueAddress: '610 N Tyler Street, Dallas, TX 75208',
            description: (
                <>
                    It’s not <i>exactly</i> a wedding in the sense that we’re
                    already married, but it’s <i>kind of like a wedding</i> in
                    the sense that it’s a{' '}
                    <b>wedding-themed-one-year-anniversary-celebration.</b>
                </>
            ),
        },
        story: {
            title: 'Our Story',
            sections: [
                {
                    text: (
                        <>
                            <b>
                                We met in Spring 2016 through our involvement in
                                Ignite Texas,
                            </b>{' '}
                            and soon after began our dating relationship. Our
                            college dating life consisted of many study-dates,
                            movie nights, checking out new restaurants, and
                            classic outdoor Austin activities. After graduating,
                            we took our relationship long-distance as Maddy went
                            to Tulsa, Oklahoma to become a kindergarten teacher
                            with Teach for America, and Andrew started work as a
                            web designer in Dallas, Texas.
                        </>
                    ),
                    image: {
                        src: data.story1.childImageSharp.fluid,
                        alt: 'Maddy and Andrew at their first formal event',
                    },
                },
                {
                    text: (
                        <>
                            <b>Andrew proposed in May 2019</b> during our summer
                            trip to Big Bend National Park in Terlingua, Texas.{' '}
                            We had been there three years prior and it was a
                            turning point in our relationship, so we were
                            excited to go back and stay in the same funky
                            AirBNB: a renovated school bus, on top of which
                            Andrew popped the big question! We spent the rest of
                            the weekend hiking and happy-crying, before
                            celebrating with not one, but two surprise
                            engagement parties on the way home.
                        </>
                    ),
                    image: {
                        src: data.story2.childImageSharp.fluid,
                        alt:
                            'Maddy and Andrew on top of a bus after Andrew proposed in Big Bend',
                    },
                },
                {
                    text: (
                        <>
                            <b>As many of you know, we are already married!</b>{' '}
                            We had originally planned our wedding for August
                            2020, but postponed due to the pandemic. However, we
                            did not allow that to change our plans for marriage.
                            We became husband and wife on July 4, 2020 at Lake
                            LBJ with our family in attendance.
                            <br />
                            <br />
                            Since then, we’ve been enjoying all the fun that
                            comes with being married! We have quickly made
                            Dallas feel like home, and we love sharing
                            friendships, exploring the city together, and the
                            endless amount of quality time. Also, Andrew reaches
                            all the tall shelves and is an excellent cook, and
                            Maddy loves to clean and keep everything organized.
                            It’s a win for both of us!
                        </>
                    ),
                    image: {
                        src: data.story3.childImageSharp.fluid,
                        alt: 'Maddy and Andrew at their wedding',
                    },
                },
            ],
        },
    };

    return (
        <Layout className="home-page" wide>
            <SEO title="Home" />
            <AnimateIn onScroll>
                <div className="hero">
                    <Heading level={1} size={0}>
                        {content.title}
                    </Heading>
                    <Heading level={2} size={3}>
                        {content.subtitle}
                    </Heading>
                </div>
            </AnimateIn>
            <Controller>
                <Scene triggerHook="onEnter" duration={'250%'}>
                    {(progress) => (
                        <AnimateIn onScroll delay={150}>
                            <div className="event-intro">
                                <div className="event-intro--left">
                                    <ZoomingImageWrapper
                                        fluid={data.ma2.childImageSharp.fluid}
                                        alt="Maddy and Andrew smiling at a coffee shop"
                                        progress={progress}
                                    />
                                </div>
                                <div className="event-intro--right">
                                    <ZoomingImageWrapper
                                        className="event-intro--image_right"
                                        fluid={data.ma1.childImageSharp.fluid}
                                        alt="Maddy and Andrew touching noses"
                                        progress={progress}
                                    />

                                    <Heading level={2} size={3}>
                                        {content.event.date}{' '}
                                        <i>at {content.event.time}</i>
                                    </Heading>
                                    <Text size="large" className="venue">
                                        {content.event.venueName} –{' '}
                                        <i>{content.event.venueAddress}</i>
                                    </Text>
                                    <Text size="large">
                                        <Link
                                            to="/wedding"
                                            className="secondary-hover"
                                        >
                                            Details
                                        </Link>
                                    </Text>
                                </div>
                            </div>
                        </AnimateIn>
                    )}
                </Scene>
            </Controller>
            <AnimateIn onScroll>
                <div className="event-description">
                    <Text size="large">{content.event.description}</Text>
                </div>
            </AnimateIn>
            <AnimateIn onScroll>
                <div className="event-video">
                    <video muted loop autoPlay playsInline>
                        <source src={ChestBump} type="video/mp4" />
                    </video>
                </div>
            </AnimateIn>
            <div className="story">
                <AnimateIn onScroll>
                    <Heading level={2} size={1}>
                        {content.story.title}
                    </Heading>
                </AnimateIn>
                {map(content.story.sections, (storyContent, index) => (
                    <StorySection
                        storyContent={storyContent}
                        imageLeft={index % 2 !== 0}
                        index={index}
                    />
                ))}
            </div>
            <div className="gallery-link">
                <Heading level={2} size={3}>
                    <Link to="/gallery">View Photo Gallery</Link>
                </Heading>
            </div>
        </Layout>
    );
};

export default IndexPage;

export const query = graphql`
    query {
        ma1: file(relativePath: { eq: "MA-1.jpg" }) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        ma2: file(relativePath: { eq: "MA-2.jpg" }) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        story1: file(relativePath: { eq: "ma-young.jpg" }) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        story2: file(relativePath: { eq: "big-bend.jpg" }) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        story3: file(relativePath: { eq: "lake-wedding.jpg" }) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`;
